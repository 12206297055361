export const environment = {
  production: false,
  backendHost: "https://sandboxapi.pay2in.com/",
  // backendHost: "https://testapi.pay2in.com/",
  //backendHost: "http://192.168.1.87:3000/",
  domain: "https://sandbox.pay2in.com/",
  documentURL: "https://api.pay2local.com/",
  project: "pay2in",
  serverType: "prod", //dev_india OR sandbox_india OR prod_india
};
